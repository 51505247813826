/** @jsx jsx */
import React from "react";
import { Box, jsx } from "theme-ui";
import Header from "./Header";
import Footer from "./Footer";

export default ({ children }) => {
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "grid",
        gridTemplateRows: "auto 1fr auto",
        maxWidth: ["100vw", null, "1600px"],
        margin: "0 auto",
      }}
    >
      <Header />
      <main
        sx={{
          mb: 5,
          maxWidth: "100vw",
        }}
        id="mainContent"
      >
        {children}
      </main>
      <Footer />
    </div>
  );
};
