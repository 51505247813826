/** @jsx jsx */
import React, { useState } from "react";
import { Box, jsx } from "theme-ui";
import { Link, useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Hamburger from "hamburger-react";
import { FaTwitter, FaFacebookF, FaYoutube, FaInstagram } from "react-icons/fa";

const Header = () => {
  const [isOpen, setOpen] = useState(false);
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      graphCmsPagesContact(
        id: { eq: "PagesContact:cksj3wi4g15jy0b64l2adf03h:PUBLISHED" }
      ) {
        contactAddress {
          raw
          html
        }
        contactTelephone
        contactEmail
        twitter
        facebook
        instagram
        youtube
      }
    }
  `);
  return (
    <header sx={styles}>
      <Box className="topRow">
        <Link to="/" aria-label="Home page">
          <StaticImage
            src="../images/logo-tcuk.jpg"
            alt="Theatre Chaplaincy Logo"
            placeholder="blurred"
            layout="fixed"
            //width={150}
            className="headerLogo"
          />
        </Link>
        <Hamburger toggled={isOpen} toggle={setOpen} />
      </Box>

      <nav aria-label="Main Menu">
        <ul className={isOpen ? "active" : ""}>
          <li className="home">
            <Link to="/" activeClassName="active">
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" activeClassName="active">
              About Us
            </Link>
          </li>
          <li>
            <Link to="/membership" activeClassName="active">
              Membership
            </Link>
          </li>
          <li>
            <Link to="/events" activeClassName="active">
              Events
            </Link>
          </li>
          <li>
            <Link to="/news" activeClassName="active">
              News
            </Link>
          </li>
          <li>
            <Link to="/links" activeClassName="active">
              Links
            </Link>
          </li>
          <li>
            <Link to="/contact" activeClassName="active">
              Contact
            </Link>
          </li>
        </ul>
        <Box className={isOpen ? "navSocialMobile active" : "navSocialMobile"}>
          <a href={data.graphCmsPagesContact.twitter}>
            <FaTwitter />
          </a>
          <a href={data.graphCmsPagesContact.facebook}>
            <FaFacebookF />
          </a>
          <a href={data.graphCmsPagesContact.youtube}>
            <FaYoutube />
          </a>
          <a href={data.graphCmsPagesContact.instagram}>
            <FaInstagram />
          </a>
        </Box>
      </nav>
      <Box className={isOpen ? "navSocialDesktop active" : "navSocialDesktop"}>
        <a href={data.graphCmsPagesContact.twitter}>
          <FaTwitter />
        </a>
        <a href={data.graphCmsPagesContact.facebook}>
          <FaFacebookF />
        </a>
        <a href={data.graphCmsPagesContact.youtube}>
          <FaYoutube />
        </a>
        <a href={data.graphCmsPagesContact.instagram}>
          <FaInstagram />
        </a>
      </Box>
    </header>
  );
};

export default Header;

const styles = {
  mx: [0, 0],
  display: ["block", null, "flex"],
  justifyContent: "center",
  position: "absolute",
  width: ["100vw", null, "100%"],
  zIndex: 5,
  "& .headerLogo": {
    width: ["100px !important", "150px !important", "150px !important"],
    height: ["100px !important", "150px !important", "150px !important"],
  },
  nav: {
    position: "relative",
    //width: ["100vw", "auto"],
    height: ["100vh", null, "auto"],
  },
  ul: {
    position: ["absolute", null, "relative"],
    transform: ["translateX(-105vw)", null, "none"],
    top: ["0px", "0px", "auto"],
    background: ["black", "black", "none"],
    pl: 0,
    my: 0,
    width: ["100vw", null, "auto"],
    display: ["block", null, "flex"],
    pb: ["30px", "auto"],
    li: {
      listStyle: "none",
      width: ["100%", null, "150px"],
      height: ["50px", null, "150px"],
      border: ["1px solid white"],
      borderLeft: [0, null, "1px solid white"],
      borderRight: [0, null, "1px solid white"],
      borderBottom: [0, null, "1px solid white"],
      bg: ["black", null, "rgb(0 0 0 / 20%)"],
      ml: [0, 2],
      "&:last-child": {
        borderBottom: ["1px solid white"],
      },
      "&.home": {
        display: ["block", "none"],
      },
      a: {
        textDecoration: "none",
        display: ["flex"],
        alignItems: ["center", null, "flex-end"],
        pb: "5px",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        color: "white",
        textAlign: "center",
        "&.active": {
          bg: "house1",
        },
      },
      "&:hover, &:focus-within": {
        bg: "house1",
        borderColor: "house1",
        a: {
          color: "white",
        },
      },
    },
    "&.active": {
      transform: "translateX(0)",
    },
  },
  "& .hamburger-react": {
    display: ["block", null, "none"],
    color: "white",
    bg: "rgb(0 0 0 / 30%)",
  },
  //mobile top row
  "& .topRow": {
    display: ["flex", null, "block"],
    justifyContent: "space-between",
    width: ["100vw", null, "auto"],
  },
  "& .navSocialMobile": {
    display: ["flex", null, "none"],
    justifyContent: "center",
    width: ["100%", null, "auto"],
    background: ["black", null, "none"],
    a: {
      color: "white",
      display: ["block", "block"],
      fontSize: "2em",
      width: ["48px", null, "auto"],
      height: ["48px", null, "auto"],
    },
    transform: ["translateX(-100vw)", null, "none"],
    position: ["absolute", null, "relative"],
    top: ["360px"],
    "&.active": {
      transform: "translateX(0)",
    },
  },
  "& .navSocialDesktop": {
    display: ["none", null, "block"],
    justifyContent: "center",
    width: ["100%", null, "auto"],
    background: ["black", null, "none"],
    a: {
      color: "white",
      display: ["block", null, "block"],
      fontSize: "2em",
      width: ["48px", null, "auto"],
      height: ["48px", null, "auto"],
    },
    transform: ["translateX(-100vw)", null, "none"],
    position: ["absolute"],
    top: ["360px"],
    right: "5px",
    "&.active": {
      transform: "translateX(0)",
    },
  },
};
