/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";
import { Box, Text } from "@theme-ui/components";
import { FaEnvelope, FaAt, FaPhoneAlt } from "react-icons/fa";
import { StaticImage } from "gatsby-plugin-image";
import Obfuscate from "react-obfuscate";
import { useStaticQuery, graphql } from "gatsby";

const Footer = () => {
  const currYear = new Date().getFullYear();

  const data = useStaticQuery(graphql`
    query FooterQuery {
      graphCmsPagesContact(
        id: { eq: "PagesContact:cksj3wi4g15jy0b64l2adf03h:PUBLISHED" }
      ) {
        contactAddress {
          raw
          html
        }
        contactTelephone
        contactEmail
        twitter
        facebook
        instagram
        youtube
      }
    }
  `);

  return (
    <footer sx={styles}>
      <Box className="container">
        <Box className="contactDetails">
          <Box className="contactDetailItem">
            <FaEnvelope alt="" />
            <Box>
              <div
                sx={{ p: { mt: 0 } }}
                dangerouslySetInnerHTML={{
                  __html: data.graphCmsPagesContact.contactAddress.html,
                }}
              />
            </Box>
          </Box>
          <Box className="contactDetailItem">
            <FaAt alt="" />
            <Box>
              <Obfuscate email={data.graphCmsPagesContact.contactEmail} />
            </Box>
          </Box>
          <Box className="contactDetailItem">
            <FaPhoneAlt alt="" />
            <Box>
              <Obfuscate tel={data.graphCmsPagesContact.contactTelephone} />
            </Box>
          </Box>
        </Box>
        <Box className="companyDetails">
          <Box className="companyDetailsInner">
            <p>
              Theatre Chaplaincy UK is proud to be a member of Acting for Others
              (The Combined Theatrical Charities)
              <br />
              <br />
              Registered Company Number 00215311 (England and Wales), Charity
              no: 207315
            </p>

            <StaticImage
              src="../images/logo-tcuk.jpg"
              alt="Theatre Chaplaincy Logo"
              placeholder="blurred"
              //layout="fixed"
              width={120}
            />
          </Box>
          <Box className="footerLogos">
            <a href="https://www.actingforothers.co.uk/">
              <StaticImage
                src="../images/logo-acting-for-others.png"
                alt="Acting For Others Logo"
                layout="fixed"
                width={100}
              />
            </a>
          </Box>
        </Box>
      </Box>
      <Box className="siteCredits">
        © Theatre Chaplaincy UK {currYear}.{" "}
        <a href="https://media.graphcms.com/Cboq0a2XRGiFlpBJKBvR">
          Privacy Policy
        </a>
        . Website by{" "}
        <a href="https://www.exposuredesign.co.uk" target="_blank">
          Exposure Design
        </a>
      </Box>
    </footer>
  );
};

export default Footer;

const styles = {
  bg: "black",
  color: "white",

  "& .container": {
    px: [3, 3, 5],
    display: ["block", "flex"],
    justifyContent: "space-between",
    py: [2, 3, 4],
    "& .contactDetails": {
      width: ["100%", "50%", "40%"],
      mt: "1em",
      "& .contactDetailItem": {
        mb: 4,
        display: "flex",
        justifyContent: ["center", "left"],
        textAlign: ["center", "left"],
        svg: {
          width: "1.5em",
          height: "1.5em",
        },
        "& > div": {
          ml: 3,
          a: {
            color: "white",
          },
        },
      },
    },
    "& .companyDetails": {
      width: ["100%", "50%", "40%"],
    },
    "& .companyDetailsInner": {
      display: ["flex", "flex"],
      justifyContent: ["center", "flex-end"],
      flexWrap: ["wrap", "nowrap"],
      mb: 5,
      p: {
        width: ["100%", "80%"],
        textAlign: "center",
        mr: 3,
        mb: [4, "auto"],
      },
      "& .gatsby-image-wrapper": {
        height: "120px",
      },
    },
    "& .footerLogos": {
      display: "flex",
      alignItems: "center",
      justifyContent: ["center", "flex-start"],
      my: [4, 3],
      a: {
        mx: 3,
      },
    },
  },
  "& .siteCredits": {
    borderTop: "1px solid #ccc",
    py: 3,
    px: 3,
    textAlign: "center",
    a: {
      color: "white",
      "&:hover, &:focus-within": {
        textDecoration: "none",
        color: "white",
      },
    },
  },
};
